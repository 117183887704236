import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../App/Layout"
import Navbar from "../App/Navbar"
import PageBanner from '../Common/PageBanner'
import Footer from "../App/Footer"


export const query = graphql`
  query($slug: String!) {
    allStrapiLifeAtCompanies(filter: {festival: {eq: $slug}}) {
        edges {
            node {
              festival
              images {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              bannerImg {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
      }
  }
`


function LifeAtCompanyDynamic({ data }) {
    const { festival, images} = data.allStrapiLifeAtCompanies.edges[0].node
    const { bannerImg} = data.allStrapiLifeAtCompanies.edges[0].node
	return (
    <Layout>
    <Navbar />
    {/* <PageBanner
                pageTitle="Life At Company Festival" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Life At Company Festival" 
            /> */}
            <section>
            {/* {bannerImg?.map((img, index) => ( */}
            <img src={bannerImg.localFile.childImageSharp.original.src}/>
            {/* ))} */}
            </section>
		<div>
       <div className="gallery-area pt-50 pb-70">
                <div className="container-fluid">
                    <div className="row">
                    {images?.map((img, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-gallery-item">
                                <div class="img-wrapper">
                                    <img className="inner-img" src={img.localFile.childImageSharp.original.src}  />
                                   
                                      </div> 
                                  
                                </div>
                            </div>
                        ))}
                        
                    </div>
                    
                    
                    {/* <div className="row">
                        <OwlCarousel 
                            className=""
                            {...options}
                        > 
                            {imgs.map((j, k) => (
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-gallery-item">
                                        <Link 
                                            to="#"
                                            onClick={e => {
                                                e.preventDefault(); 
                                                setIsOpenImage(true); 
                                                setPhotoIndex(k);}
                                            }
                                        >
                                            <img src={j} alt="event" />
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div> */}
                </div>

                {/* Lightbox */}
                {/* {isOpenImage && (
                    <Lightbox
                        mainSrc={imgs[photoIndex]}
                        nextSrc={imgs[(photoIndex + 1) % imgs.length]}
                        prevSrc={imgs[(photoIndex + imgs.length - 1) % imgs.length]}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + imgs.length - 1) % imgs.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % imgs.length)
                        }
                    />
                )} */}
            </div>
	
		</div>
    <Footer />
        </Layout>
	)
}

export default LifeAtCompanyDynamic